
type TProjectHeadliner = { section?: string; }

const ProjectHeadliner = ({  
    section = "Portfolio"
}: TProjectHeadliner) => {
    return (
        <div className="visual-stories-hero wf-section">
            <div className="div-block-21">
                <h1 className="headliner">{section}</h1>
                <div className="category-p">
                    {/* <div className="container-21 w-container">
                        <p className="paragraph-7 ux-paragraph">
                            I build and activate brands through strategy, emotion, visuals, and
                            creative expression. I like to make the little things better, and the
                            big things awesome. <br />
                        </p>
                    </div> */}
                </div>
                {/* <img
                    src="https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/5e10d5a698f2dc322bd730e5_kyson-camera%402x%20(1).png"
                    sizes="100vw"
                    srcSet="https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/5e10d5a698f2dc322bd730e5_kyson-camera%402x%20(1)-p-500.png 500w, https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/5e10d5a698f2dc322bd730e5_kyson-camera%402x%20(1).png 1776w"
                    alt=""
                    className="me"
                /> */}
            </div>
        </div>
    );
};

export default ProjectHeadliner;