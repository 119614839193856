import { BrowserRouter } from "react-router-dom";
import Navigation from "./Routes.route";

const Entry = () => {
    return (
        <BrowserRouter>
            <Navigation />
        </BrowserRouter>
    )
}

export default Entry;