import { Link } from "components/Link"

import BelizeHandymenLogo from "assets/vectors/belizehandymen.svg"

const MobileProjects = () => {
    return (
        <div className="case-studies-section homepage wf-section">

            <div data-w-id="c04c5f17-55ba-4a1c-81c5-904816d5500c"
                className="cs-bg-div handy">
                <div className="cs-text-block-div handy">
                <img
                    src={BelizeHandymenLogo}
                    width="200" 
                    alt="" 
                    className="cs-block-brand-logo handy" />
                    <div className="cs-block-role">FREELANCE MOBILE DEVELOPER</div>
                    <div className="cs-block-title">Platform Linking Service Professionals To The Public</div>
                    <Link to="https://apps.apple.com/us/app/belize-handymen/id6450762512" target="_blank" className="button-3 cs-block dark-bg w-button">UNDER NDA - VIEW FINAL PRODUCT</Link>
                </div>
            </div>

        </div>
    )
}

export default MobileProjects;