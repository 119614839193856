import { Route, Routes } from "react-router-dom";
import Homepage from "./Homepage/Homepage";
import About from "./About/About.screen";
import Mobile from "./Mobile/Mobile.screen";
import Websites from "./Websites/Websites.screen";
import Belize from "./Belize/Belize.screens";


const Navigation = () => {
    return (
        <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/about" element={<About />} />
            <Route path="/mobile" element={<Mobile />} />
            <Route path="/websites" element={<Websites />} />
            <Route path="/belize" element={<Belize />} />
        </Routes>
    )
}

export default Navigation;