import GenericLayout from "layouts/Generic/Generic.layouts";
import ProjectHeadliner from "sections/Headliner/ProjectsHeadliner/ProjectHeadliner.sections";
import BelizeProjects from "sections/Projects/BelizeProjects.sections.tsx/BelizeProjects.sections";
import React from "react";

const BelizeScreen = () => {
    return (
        <GenericLayout>
           <React.Fragment>
              <ProjectHeadliner section={"Belize"}/>
              <BelizeProjects />
           </React.Fragment> 
        </GenericLayout>
    );
};

export default BelizeScreen;