import Footer from "sections/Footer/Footer.sections";
import Navbar from "sections/Navbar/Navbar.sections";

type tGenericLayoutProps = {
    children?: string | number | JSX.Element;
    bodyStyle?: string;
}

const GenericLayout = ({ 
    children,
    bodyStyle = "body"
}: tGenericLayoutProps) => {
    return (
        <body className={bodyStyle}>
            <Navbar />
                {children}
            <Footer dark={bodyStyle !== "body"} />      
        </body>
    );
};

export default GenericLayout;