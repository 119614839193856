import HeadlinerHeroImage from "components/HeadlinerHeroImage/HeadlinerHeroImage.components";
import SocialIcons from "components/SocialIcons/SocialIcons.components";
import { Link } from "components/Link"

const Headliner = () => {
  return (
    <div className="intro w-container">
      <h1 data-w-id="7a53d6a4-ca74-bc58-a608-78bfa5496846" className="heading">
        <strong className="bold-text">
          Caleb is a Software Consultant with years of commercial-grade experience designing and developing cross platform applications.
        </strong>
      </h1>
      <img
        src="https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/5b91c9bcae89e57253d599a9_horizontal-line.svg"
        alt=""
        className="image"
      />
      <p className="paragraph">
        I design and develop web, mobile and desktop apps. Currently,
        I&#x27;m a Freelancer available contract {" "}
        <Link to="mailto:contact@calebnicholas.com?subject=Hey%20Caleb%20N." className="link-5">
            hires
        </Link>
        .
      </p>

      <SocialIcons />
      {/* <HeadlinerHeroImage /> */}
    </div>
  );
};

export default Headliner;
