import { Link } from "components/Link"
import GithubIcon from "assets/images/icon/github.png"

const SocialIcons = () => {
    return (
        <div id="social-media" className="div-block-8">
        <Link
          to="https://www.linkedin.com/in/calebnicholas/"
          target="_blank"
          className="social-links w-inline-block"
        >
          <img
            src="https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/5b57e4a86a841038b8bc0af7_linkedin-3-xxl%20copy.png"
            width="42"
            alt=""
            className="image-2"
          />
        </Link>

        <Link
          to="https://github.com/cjnich"
          target="_blank"
          className="social-links w-inline-block"
        >
          <img
            src={GithubIcon}
            width="42"
            alt=""
            className="image-2 github"
          />
        </Link>
      </div>
    );
};

export default SocialIcons;