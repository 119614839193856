import GenericLayout from "layouts/Generic/Generic.layouts";
import ProjectHeadliner from "sections/Headliner/ProjectsHeadliner/ProjectHeadliner.sections";
import WebsiteProjects from "sections/Projects/WebsiteProjects.sections.tsx/WebsiteProjects.sections";
import React from "react";

const Websites = () => {
    return (
        <GenericLayout>
            <React.Fragment>
                <ProjectHeadliner section={"Websites"}/>
                <WebsiteProjects/>
            </React.Fragment>
        </GenericLayout>
    );
}

export default Websites;