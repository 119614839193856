import React from "react";

type TRecentBrandProps = { visible?: boolean; };

const RecentBrands = ({ visible }: TRecentBrandProps) => {
    return (
        <React.Fragment>
            <div className="x-line top wf-section">
                <div className="w-hidden-tiny w-container">
                    <img
                        src="https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/5b913a118854430d1d59670a_x.svg"
                        style={{ opacity: 0 }}
                        data-w-id="905eb075-bfd0-bd13-2bd9-7877be0e3bee"
                        alt=""
                        className="image-13"
                    />
                    <img
                        src="https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/5b913a51b07f22c423841fab_vertical-line.svg"
                        style={{ opacity: 0 }}
                        data-w-id="83a5382e-03af-7ed4-eea3-d02e208a3d83"
                        alt=""
                        className="image-14"
                    />
                </div>
            </div>

            <div className="section-2 w-hidden-small w-hidden-tiny wf-section">
                {visible && <React.Fragment>
                    <div className="container-8 w-container">
                        <div data-w-id="d68c031e-14d0-ab30-2ea0-5ff450ce36e0"
                            className="text-block">A few brands I&#x27;ve
                            worked with
                        </div>
                        <img
                            src="https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/5f49512742ae0d3fa60de890_kyson-logos-1.png"
                            width="735"
                            data-w-id="d68c031e-14d0-ab30-2ea0-5ff450ce36e2"
                            srcSet="https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/5f49512742ae0d3fa60de890_kyson-logos-1-p-500.png 500w, https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/5f49512742ae0d3fa60de890_kyson-logos-1.png 800w"
                            sizes="(max-width: 767px) 100vw, (max-width: 991px) 728px, 735px"
                            alt=""
                            className="image-4" />
                    </div>
                </React.Fragment>}
            </div>
        </React.Fragment>
    )
}

export default RecentBrands;