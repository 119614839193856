import { Link } from "components/Link"

const BelizeProjects = () => {
    return (
        <div className="case-studies-section homepage wf-section">

            <div data-w-id="c04c5f17-55ba-4a1c-81c5-904816d5500c"
                className="cs-bg-div transport">
                <div className="cs-text-block-div transport">
                <div className="cs-block-brand-logo transport" />
                    <div className="cs-block-role">PRODUCT OWNER</div>
                    <div className="cs-block-title">A product to revolutionize the meaning of public transport in Belize. </div>
                    <Link to="" className="button-3 cs-block dark-bg w-button">COMING SOON</Link>
                </div>
            </div>

            <div data-w-id="c04c5f17-55ba-4a1c-81c5-904816d5503a" className="cs-bg-div vehicles">
                <div className="cs-text-block-div vehicles">
                <div className="cs-block-brand-logo vehicles" />
                    <div className="cs-block-role">PRODUCT OWNER</div>
                    <div className="cs-block-title">Reliable shop for premium vehicle both local and abroad with the peace of mind in quality and delivery to your door.</div>
                    <Link to="" className="button-3 cs-block dark-bg w-button">COMING SOON</Link>
                </div>
            </div>

            <div data-w-id="c04c5f17-55ba-4a1c-81c5-904816d5503a" className="cs-bg-div yard">
                <div className="cs-text-block-div yard">
                <div className="cs-block-brand-logo yard" />
                    <div className="cs-block-role">PRODUCT OWNER</div>
                    <div className="cs-block-title">Reach the right professionals near you to get the job done.</div>
                    <Link to="" className="button-3 cs-block dark-bg w-button">COMING SOON</Link>
                </div>
            </div>

            <div data-w-id="c04c5f17-55ba-4a1c-81c5-904816d5503a" className="cs-bg-div commerce">
                <div className="cs-text-block-div commerce">
                <div className="cs-block-brand-logo commerce" />
                    <div className="cs-block-role">PRODUCT OWNER</div>
                    <div className="cs-block-title">Shop to your heart's content and have the option of home deivery country-wide.</div>
                    <Link to="" className="button-3 cs-block dark-bg w-button">COMING SOON</Link>
                </div>
            </div>

            <div data-w-id="c04c5f17-55ba-4a1c-81c5-904816d5503a" className="cs-bg-div security">
                <div className="cs-text-block-div security">
                <div className="cs-block-brand-logo security" />
                    <div className="cs-block-role">PRODUCT OWNER</div>
                    <div className="cs-block-title">Reach your destination and back with peace of mind.</div>
                    <Link to="" className="button-3 cs-block dark-bg w-button">COMING SOON</Link>
                </div>
            </div>

        </div>
    )
}

export default BelizeProjects;