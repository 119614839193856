import CTACard from "components/CTACard/CTACard.components";
import GenericLayout from "layouts/Generic/Generic.layouts";
import React from "react";

const About = () => {
    return (
        <GenericLayout bodyStyle={"body-3"}>
            <React.Fragment>
                <div className="section-11 wf-section">
                    <div className="div-block-11">
                        <img
                            src="https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/5bf5e6524fac809aa56a10b1_cover-image.jpg"
                            sizes="100vw"
                            srcSet="https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/5bf5e6524fac809aa56a10b1_cover-image-p-500.jpeg 500w, https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/5bf5e6524fac809aa56a10b1_cover-image-p-800.jpeg 800w, https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/5bf5e6524fac809aa56a10b1_cover-image-p-1600.jpeg 1600w, https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/5bf5e6524fac809aa56a10b1_cover-image-p-2000.jpeg 2000w, https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/5bf5e6524fac809aa56a10b1_cover-image.jpg 2560w"
                            alt=""
                            className="image-52"
                        />
                    </div>
                    <div
                        data-poster-url="https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/5ba7eca7c325b631243512d0_case-studies-video-1-poster-00001.jpg"
                        data-video-urls="https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/5ba7eca7c325b631243512d0_case-studies-video-1-transcode.mp4,https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/5ba7eca7c325b631243512d0_case-studies-video-1-transcode.webm"
                        data-autoplay="true"
                        data-loop="true"
                        data-wf-ignore="true"
                        className="background-video-5 w-background-video w-background-video-atom"
                    >
                        {/* <video
                            id="b0044fff-01d7-8dd3-11f6-9f52829a603a-video"
                            autoPlay={true}
                            loop={true}
                            style={{
                                backgroundImage:
                                    'url(https://www.calebnicholas.com/"https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/5ba7eca7c325b631243512d0_case-studies-video-1-poster-00001.jpg")'
                            }}
                            muted={true}
                            playsInline={true}
                            data-wf-ignore="true"
                            data-object-fit="cover"
                        >
                            <source
                                src="https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/5ba7eca7c325b631243512d0_case-studies-video-1-transcode.mp4"
                                data-wf-ignore="true"
                            />
                            <source
                                src="https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/5ba7eca7c325b631243512d0_case-studies-video-1-transcode.webm"
                                data-wf-ignore="true"
                            />
                        </video> */}
                    </div>
                    <div className="container-12 w-container">
                        <div>
                            <h1 className="heading">
                                <strong className="bold-text about-hero">
                                    Caleb is a developer
                                    <br /> who loves a good challenge.
                                </strong>
                            </h1>
                        </div>
                    </div>
                </div>


                <div className="container-13 about w-container">
                    <img
                        src="https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/5b913a118854430d1d59670a_x.svg"
                        data-w-id="36b53c67-5c9f-e70c-083e-2987667ba833"
                        alt=""
                        className="image-13 about"
                    />
                    <img
                        src="https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/5b9ee3d1fb9e09136536d75b_line-grey.svg"
                        data-w-id="36b53c67-5c9f-e70c-083e-2987667ba834"
                        alt=""
                        className="image-14 about"
                    />
                </div>
                <div className="section-12 wf-section">
                    <div className="w-container">
                        <div className="columns-2 w-row">
                            <div className="column-11 w-col w-col-4">
                                {/* <img
                                    src="https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/5e498ab19ce5341ff46becc8_image-asset-11.jpg"
                                    alt=""
                                    className="image-39"
                                /> */}
                                <div className="text-block-16">
                                    <em className="italic-text">
                                        I'm working to make the road in front a pleasant one. Building tools that I believe matter and aids the greater betterment of mankind. When I'm doing that I am usually travelling to somewhere new, meeting new and amazing people and experiencing their amazing cultures and practices.
                                    </em>
                                </div>
                            </div>
                            <div className="column-8 w-col w-col-4">
                                <div className="h5">ABOUT&nbsp;Caleb Nicholas</div>
                                <div className="text-block-15">
                                    Caleb is a Freelance designer and developer with over 6 years of commercial
                                    experience. He’s worked with a variety local brands as well as global brands ranging from
                                    small non-profits to large tech companies with ambitious goals. He
                                    has a unique talent at seeing the big picture potential in a product
                                    or brand and then bringing it to life.
                                    <br />
                                    <br />
                                    Each company Caleb has worked for has carried a strong mission
                                    behind the work—ranging from sustainability and transportation, to
                                    education. He tries to keep that same ethos for doing good in his
                                    personal life as well. He strongly believes that design and
                                    storytelling is a powerful medium to create change. <br />
                                    <br />
                                    Caleb is comfortable working on a range of projects from designing
                                    native apps to collaborating on set with a production crew. Whether
                                    he’s building a mobile app, managing servers, or crafting
                                    prototypes on his laptop, Caleb always brings a quality of work and
                                    creativity that is hard to match.
                                    <br />
                                    <br />
                                    Outside of work Caleb finds inspiration through reading,
                                    sports, and traveling with his wife and little boy. His
                                    hunger and curiosity for life drives him to experience everything
                                    and document all that it has to offer. This thirst for life is
                                    evident in all of his work.
                                    <br />
                                </div>
                            </div>
                            <div className="column-experience capa w-col w-col-4">
                                <div className="h5">PROFESSIONAL&nbsp;CAPABILITIES</div>
                                <div className="text-block-15 list">
                                    Websites
                                    <br />
                                    iOS and Android Apps
                                    <br />
                                    Growth Strategy
                                    <br />
                                    Market Research
                                    <br />
                                    E-commerce Design
                                    <br />
                                    Desktop Apps
                                </div>
                                <div className="h5 yo">Brand Capabilities</div>
                                <div className="text-block-15 list">
                                    Art Direction
                                    <br />
                                    Real-Estate Photography
                                    <br />
                                    Drone Photography
                                    <br />
                                    Marketing Campaigns
                                    <br />
                                    Market Positioning
                                    <br />
                                    Branding & Identity
                                    <br />
                                </div>
                                <div className="h5 yo">Clients &amp; Collaborators</div>
                                <div className="text-block-15 list">
                                    WellMadeSolutions
                                    <br />
                                    AR
                                    <br />
                                    OneNetwork
                                    <br />
                                    Invictus
                                    <br />
                                    CIE
                                    <br />
                                    CNC
                                    <br />
                                    KhariDesign
                                    <br />
                                    Pyranicx
                                </div>
                            </div>
                        </div>
                        <div className="w-layout-grid grid">
                            <img
                                src="https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/5df179a2245d001ab7b1c4a8_static1.squarespace-2%402x.png"
                                height={200}
                                sizes="100vw"
                                srcSet="https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/5df179a2245d001ab7b1c4a8_static1.squarespace-2%402x-p-500.png 500w, https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/5df179a2245d001ab7b1c4a8_static1.squarespace-2%402x-p-800.png 800w, https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/5df179a2245d001ab7b1c4a8_static1.squarespace-2%402x.png 1322w"
                                alt=""
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/5df179a2f3b088a246836fac_Mask%20Group%20345%402x.png"
                                height={200}
                                sizes="100vw"
                                srcSet="https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/5df179a2f3b088a246836fac_Mask%20Group%20345%402x-p-500.png 500w, https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/5df179a2f3b088a246836fac_Mask%20Group%20345%402x.png 1322w"
                                alt=""
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/5df179a29bca42d1011915bf_1598642_10151885845330423_877999036_o%402x.png"
                                height={200}
                                sizes="100vw"
                                srcSet="https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/5df179a29bca42d1011915bf_1598642_10151885845330423_877999036_o%402x-p-500.png 500w, https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/5df179a29bca42d1011915bf_1598642_10151885845330423_877999036_o%402x.png 1322w"
                                alt=""
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/5df179a237d24d9d66c30676_vsco5dc6e99670e6e%402x.png"
                                sizes="100vw"
                                srcSet="https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/5df179a237d24d9d66c30676_vsco5dc6e99670e6e%402x-p-500.png 500w, https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/5df179a237d24d9d66c30676_vsco5dc6e99670e6e%402x.png 1322w"
                                alt=""
                                className="image-54"
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/5df179a23da2ac2cbcedc003_vsco59c063092c0f8%402x.png"
                                height={200}
                                sizes="100vw"
                                srcSet="https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/5df179a23da2ac2cbcedc003_vsco59c063092c0f8%402x-p-500.png 500w, https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/5df179a23da2ac2cbcedc003_vsco59c063092c0f8%402x.png 1322w"
                                alt=""
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/5df179a3245d00d4f3b1c63f_338368_10150479516400423_1286726543_o-2%402x.png"
                                height={200}
                                sizes="100vw"
                                srcSet="https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/5df179a3245d00d4f3b1c63f_338368_10150479516400423_1286726543_o-2%402x-p-500.png 500w, https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/5df179a3245d00d4f3b1c63f_338368_10150479516400423_1286726543_o-2%402x-p-800.png 800w, https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/5df179a3245d00d4f3b1c63f_338368_10150479516400423_1286726543_o-2%402x-p-1080.png 1080w, https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/5df179a3245d00d4f3b1c63f_338368_10150479516400423_1286726543_o-2%402x.png 1322w"
                                alt=""
                            />
                        </div>
                        <div className="div-block-9" />
                        <div id="contact" className="contact-columns w-row">
                            <div className="column-11 w-col w-col-4">
                                <h1 className="heading-4">Contact</h1>
                            </div>
                            <div className="column-8 w-col w-col-4">
                                <div className="h5">Let's Work Together</div>
                                <div className="text-block-15 list">
                                    <a href="mailto:contact@calebnicholas.com?subject=Hey%20Caleb%20N." className="link-2">contact@calebnicholas.com</a>
                                    <br />
                                    <a href="https://www.linkedin.com/in/calebnicholas/" target="_blank" className="link-2">Linkedin</a>
                                    <br />
                                    <a href="https://www.youtube.com/@CalebNicholasCodes" target="_blank" className="link-2">Youtube</a>
                                </div>
                            </div>
                            <div className="column-experience w-col w-col-4">
                                <div className="h5">Experience</div>
                                <div className="text-block-15 list">
                                    Software Consultant / System&nbsp;Analyst
                                    <br />
                                    Freelance Web Designer
                                    <br />
                                    Freelance Software Devloper
                                    <br />
                                    Product Designer at WMS
                                    <br />
                                    Full-Stack Engineer at AR.
                                    <br />
                                    Web Developer at DealerSpike
                                    <br />
                                    Deployments Engineer
                                    <br />
                                    Product Specialist at ONENETWORK
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="next-project-section boosted-case-study about w-clearfix wf-section">
                   <CTACard visible={false}/> 
                </div>
                
            </React.Fragment>
        </GenericLayout>
    );
}

export default About;