import WMSLogo from "assets/vectors/wms.svg"
import BelizeanGroceriesLogo from "assets/vectors/belizeangroceries.svg"
import BelizeHandymenLogo from "assets/vectors/belizehandymen.svg"
import GenesisLogo from "assets/vectors/genesis.svg"

import { Link } from "components/Link"

const WebsiteProjects = () => {
    return (
        <div className="case-studies-section homepage wf-section">

            <div data-w-id="c04c5f17-55ba-4a1c-81c5-904816d55001" className="cs-bg-div wms-case-study">
                <div className="cs-text-block-div dark">
                    <img
                        src={WMSLogo}
                        alt="wms" 
                        className="cs-block-brand-logo" />
                    <div className="cs-block-role">Freelance Developer</div>
                    <div className="cs-block-title">Developing WMS&#x27;s Website Experience</div>
                    <div className="cs-block-description">Worked on developing the site pages according to clients design as well as designing and extending sites api and database capabilities.
                    </div>
                    <Link to="https://wellmadesolutions.com" target="_blank" className="button-3 cs-block dark-bg w-button">UNDER NDA - VIEW FINAL PRODUCT</Link>
                </div>
            </div>

            <div data-w-id="91710896-8326-e02a-4267-16d91846d13c" className="cs-bg-div belizeangroceries">
                <div className="cs-text-block-div dark">
                    <img
                        src={BelizeanGroceriesLogo}
                        width="120" 
                        alt="" 
                        className="cs-block-brand-logo" />
                    <div className="cs-block-role">Lead Full-Stack Engineer</div>
                    <div className="cs-block-title">LEAD DESIGN AND IMPLEMENTATION FOR BELIZEAN GROCERIES</div>
                    <div className="cs-block-description">Lead the design and implementation for Belizean Grocery&#x27;s Customer Web Platform, allowing customers to order groceries online from their various branches countrywide.</div>
                    <Link to="https://belizeangroceries.com" target="_blank" className="button-3 cs-block dark-bg w-button">UNDER NDA - VIEW FINAL PRODUCT</Link>
                </div>
            </div>

            <div data-w-id="4ed57176-865b-9204-829d-e5bdbd172c5e"
                className="cs-bg-div boosted-rev">
                <div className="background-video-8 w-background-video w-background-video-atom">
                    <video
                        id="89ef1c31-9a7e-d182-d68e-d68fc4f0f5b4-video"
                        autoPlay={true}
                        loop={true}
                        style={{ backgroundImage: "url(assets/images/projects/b97ee7965.jpeg)" }}
                        muted={true}
                        playsInline={true}
                        data-wf-ignore="true"
                        data-object-fit="cover">
                        <source
                            src={require("assets/media/handymen.mp4")}
                            data-wf-ignore="true" />
                    </video>
                    <div className="cs-text-block-div dark">
                        <img
                            src={BelizeHandymenLogo}
                            width="120" 
                            alt="" 
                            className="cs-block-brand-logo" />
                        <div className="cs-block-role">FREELANCE FRONTEND ENGINEER</div>
                        <div className="cs-block-title rev">Showcase website for Belize Handymen<br /></div>
                        <div className="cs-block-description">I worked as part of an amazing team of developers to bring this beautiful product to life.
                        </div>
                        <Link to="https://www.belizehandymen.com" target="_blank" className="button-3 cs-block dark-bg w-button">UNDER NDA - VIEW FINAL PRODUCT</Link>
                    </div>
                </div>
            </div>

            <div data-w-id="4ed57176-865b-9204-829d-e5bdbd172c5e"
                className="cs-bg-div boosted-rev">
                <div className="background-video-8 w-background-video w-background-video-atom">
                    <video
                        id="89ef1c31-9a7e-d182-d68e-d68fc4f0f5b4-video"
                        autoPlay={true}
                        loop={true}
                        style={{ backgroundImage: "url(assets/images/projects/b97ee7965.jpeg)" }}
                        muted={true}
                        playsInline={true}
                        data-wf-ignore="true"
                        data-object-fit="cover">
                        <source
                            src={require("assets/media/educational.mp4")}
                            data-wf-ignore="true" />
                    </video>
                    <div className="cs-text-block-div dark">
                        <img
                            src={GenesisLogo}
                            width="120" 
                            alt="" 
                            className="cs-block-brand-logo" />
                        <div className="cs-block-role">FREELANCE FULL-STACK ENGINEER</div>
                        <div className="cs-block-title rev">Building The Future Platform For Online Education In Belize <br /></div>
                        <div className="cs-block-description">Principal Engineer tasked with bringing this amazing vision to life.
                        </div>
                        <Link to="" className="button-3 cs-block dark-bg w-button">COMING SOON</Link>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default WebsiteProjects;