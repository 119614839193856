import { Link } from "components/Link"

type tFooterSectionsProps = {
    dark?: boolean;
};

const Footer = ({ dark = false }: tFooterSectionsProps) => {
    const date = new Date();

    return (
        <div className={`footer ${dark && "dark-bg"} wf-section`}>
            <div className="div-block-42"></div>
            <div className="div-block-41">
                <div className="left-footer-collumn">
                    <div className="footer-collumn">
                        <div className="cs-block-title footer-title">Work</div>
                        <div className="footer-link">
                            <Link to="/websites" className="footer-link" rel={"dofollow"}>
                                Websites
                            </Link>
                        </div>
                        <div className="footer-link">
                            <Link to="/mobile" className="footer-link" rel={"dofollow"}>
                                Mobile
                            </Link>
                        </div>
                        <div className="footer-link">
                            <Link to="/belize" className="footer-link" rel={"dofollow"}>
                                Belize
                            </Link>
                        </div>
                        <div className="footer-link">
                            <Link to="/about" className="footer-link" rel={"dofollow"}>
                                About
                            </Link>
                        </div>
                    </div>
                    <div className="footer-collumn">
                        <div className="cs-block-title footer-title">Social</div>
                        <div className="footer-link">
                            <Link 
                                to="ahttps://www.youtube.com/@CalebNicholasCodes" 
                                target="_blank"
                                className="footer-link" 
                                rel={"dofollow"}>
                                Youtube
                            </Link>
                        </div>
                        <div className="footer-link">
                            <Link
                                to="http://www.instagram.com/cal%ebni%chol%as"
                                target="_blank"
                                className="footer-link"
                                rel={"dofollow"}
                            >
                                Instagram
                            </Link>
                        </div>
                        <div className="footer-link">
                            <Link
                                to="https://www.linkedin.com/in/calebnicholas/"
                                target="_blank"
                                className="footer-link"
                                rel={"dofollow"}
                            >
                                Linkedin
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="email-desktop">
                    <div className="cs-block-title">Let&#x27;s connect</div>
                    <div className="cs-block-description newsletter">
                        I’m working on a fleet of applications to better life here in our beautiful country. 
                        Sign up to get access first.{" "}
                    </div>
                    <div className="w-form">
                        <div className="text-block-15 list">
                            <a href="mailto:contact@calebnicholas.com?subject=Hey%20Caleb%20N." className="link-5">contact@calebnicholas.com</a>
                        </div>
                    </div>
                    {/* <div className="w-form">
                        <form
                            id="wf-form-EMAIL"
                            name="wf-form-EMAIL"
                            data-name="EMAIL"
                            action="https://calebnicholas.us3.list-manage.com/subscribe/post?u=3a7177d4b5c650efd3903f75f&amp;amp;id=46275f5cfc&amp;amp;f_id=00674de2f0"
                            method="post"
                            className="form"
                        >
                            <input
                                type="email"
                                className="text-field w-input"
                                maxLength={256}
                                name="email-2"
                                data-name="Email 2"
                                placeholder="Enter your email"
                                id="email-2"
                                required={true}
                            />
                            <input
                                type="submit"
                                value="Submit"
                                data-wait="Please wait..."
                                className="submit-button w-button"
                            />
                        </form>
                        <div className="success-message w-form-done">
                            <div className="text-block-42">
                                Thanks! Keep your eyes on your inbox for good stuff to come!
                            </div>
                        </div>
                        <div className="w-form-fail">
                            <div>Oops! Something went wrong while submitting the form.</div>
                        </div>
                    </div> */}
                </div>
            </div>
            <div className="email-mobile">
                <div className="cs-block-title">Let&#x27;s connect</div>
                <div className="cs-block-description newsletter">
                    I’m working on a fleet of applications to better life here in our beautiful country. 
                    Reach out to learn more.{" "}
                </div>
                <div className="w-form">
                        <div className="text-block-15 list">
                            <a href="mailto:contact@calebnicholas.com?subject=Hey%20Caleb%20N." className="link-5">contact@calebnicholas.com</a>
                        </div>
                </div>
                {/* <div className="form-block w-form">
                    <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form"
                    >
                        <input
                            type="email"
                            className="text-field w-input"
                            maxLength={256}
                            name="email"
                            data-name="Email"
                            placeholder="Enter your email"
                            id="email"
                            required={true}
                        />
                        <input
                            type="submit"
                            value="Submit"
                            data-wait="Please wait..."
                            className="submit-button w-button"
                        />
                    </form>
                    <div className="success-message-2 w-form-done">
                        <div className="text-block-43">
                            Thanks! Keep your eyes on your inbox for good stuff to come!
                        </div>
                    </div>
                    <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                </div> */}
            </div>
            <div className="div-block-39">
                <div className="footer-details">
                    <div className="text-block-39">
                        ©{date.getFullYear()} Caleb Nicholas. All Rights Reserved.
                        <br />
                    </div>
                </div>
                {/* <div className="div-block-40">
                    <div className="social">
                        <img
                            src="https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/609db936ae19b928c6719059_instagram.png"
                            loading="lazy"
                            width="32"
                            alt=""
                        />
                    </div>
                    <div className="social">
                        <img
                            src="https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/609db936ae19b9a34d719057_twitter.png"
                            loading="lazy"
                            width="32"
                            alt=""
                        />
                    </div>
                    <div className="social">
                        <img
                            src="https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/609db936ae19b9a0bd719055_youtube.png"
                            loading="lazy"
                            width="32"
                            alt=""
                        />
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default Footer;
