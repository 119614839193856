import { Link } from "components/Link"
import React from "react";

type TCTACardProps = {
    visible?: boolean;
}

const CTACard = ({ visible = true }: TCTACardProps) => {
    if (!visible) return null;
    
    return (
        <React.Fragment>
            <Link to="http://www.saraky.com" className="link-block w-inline-block w-clearfix">
                <img
                    src="https://uploads-ssl.webflow.com/5b0eb11723332d2455b73469/5b9eee560899a42d7880d43f_sara-ky.png"
                    width={110}
                    alt=""
                    className="image-38 about"
                />
            </Link>
            <div className="div-block-5 about div-block-6">
                <div className="container-11 next w-container">
                    <h1 className="heading-3 next-project about">
                        Weekly photography
                        <br />
                        blog at saraky.com
                    </h1>
                    <Link to="http://www.saraky.com" target="_blank" className="button-3 w-button" rel="noreferrer">
                        READ&nbsp;THE&nbsp;STORIES
                    </Link>
                </div>
            </div>
        </React.Fragment>
    );
};

export default CTACard;