import { Link, LinkProps } from "react-router-dom";

type TLinkComponentProps = LinkProps;

const LinkComponent = ( props: TLinkComponentProps) => {
    return (
        <Link rel={"nofollow"} {...props} />
    );
};

export default LinkComponent;