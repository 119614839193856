import GenericLayout from "layouts/Generic/Generic.layouts";
import ProjectHeadliner from "sections/Headliner/ProjectsHeadliner/ProjectHeadliner.sections";
import MobileProjects from "sections/Projects/MobileProjects.sections.tsx/MobileProjects.sections";
import React from "react";

const Mobile = () => {
    return (
        <GenericLayout>
            <React.Fragment>
                <ProjectHeadliner section={"Mobile"}/>
                <MobileProjects />
            </React.Fragment>
        </GenericLayout>
    );
}

export default Mobile;